import * as React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {IoMdBusiness} from 'react-icons/io';
import {CiDeliveryTruck} from 'react-icons/ci';
import {TbSpeedboat} from 'react-icons/tb';
import {BsFillPersonVcardFill} from 'react-icons/bs';
import {MdOutlineCategory} from 'react-icons/md';
import {RiRouteFill} from 'react-icons/ri';
import {GiOrganigram} from 'react-icons/gi';
import {GrUserWorker} from 'react-icons/gr';
import {FaTasks} from 'react-icons/fa';
import {FiSettings, FiUserPlus} from 'react-icons/fi';
import {AiOutlineHome} from 'react-icons/ai';
import {BsQuestionDiamond} from 'react-icons/bs';
import {HiLogin, HiLogout} from 'react-icons/hi';
import {BiTask} from 'react-icons/bi';

import {useAuth} from '../../state-management';
import {shouldRenderListGroup, shouldRenderMenuItem} from './filter';


export interface MenuItemProps {
  id?: string;
  to?: string;
  display?: string;
  roleKeys?: string[]
  permissionsKey?: string
  onClick?: () => void;
  icon?: any;
}

export interface MenuItemGroup {
  label?: string;
  icon?: any
  subItems?: MenuItemProps[]
}


const DrawerMenuItems: React.FC = () => {

  const navigate = useNavigate();

  const { state, logout } = useAuth();

  const CustomMenuItem: React.FC<MenuItemProps> = ({
    id,
    to,
    display,
    onClick,
    icon: Icon
  }) => {
    const menuI = (
      <ListItemButton id={id} onClick={onClick}>
        <ListItemIcon className="ml-3">
          {Icon ? <Icon /> : null}
        </ListItemIcon>

        <ListItemText>
          {display}
        </ListItemText>
      </ListItemButton>
    );

    return to ? <Link style={{ textDecoration: 'none', color: 'black' }} to={to}>{menuI}</Link> : menuI;
  };

  const handleLogout = async () => {
    await logout()
    navigate('/login')
  }

  const getMenuItems = () => {
    const authenticatedMenuItemGroups:MenuItemGroup[] = [
      {
        subItems: [
          {
            display: 'Dashboard',
            icon: AiOutlineHome
          }
        ]
      },
      {
        label: 'Admin',
        subItems: [
          {
            display: 'Organizations',
            roleKeys: ['superadmin'],
            permissionsKey: 'Org',
            icon: GiOrganigram
          },
          {
            display: 'Marinas',
            roleKeys: ['admin'],
            permissionsKey: 'Marina',
            icon: IoMdBusiness
          },
        ]
      },
      {
        label: 'Management',
        subItems: [
          {
            display: 'Jobs',
            roleKeys: ['admin', 'manager', 'technician'],
            permissionsKey: 'Job',
            icon: BiTask
          },
          {
            display: 'Customers',
            roleKeys: ['admin', 'manager'],
            permissionsKey: 'Customer',
            icon: BsFillPersonVcardFill
          },
          {
            display: 'Boats',
            roleKeys: ['admin', 'manager', 'technician'],
            permissionsKey: 'Boat',
            icon: TbSpeedboat
          },
          {
            display: 'Users',
            permissionsKey: 'User',
            icon: GrUserWorker
          },
          {
            display: 'Trucks',
            roleKeys: ['admin', 'manager'],
            permissionsKey: 'Truck',
            icon: CiDeliveryTruck
          },
        ]
      },
      {
        label: 'Jobs Outline',
        subItems: [
          {
            display: 'Boat Types',
            roleKeys: [],
            permissionsKey: 'BoatType',
            icon: MdOutlineCategory
          },
          {
            display: 'Job Types',
            roleKeys: [],
            permissionsKey: 'JobType',
            icon: RiRouteFill
          },
          {
            display: 'Master Tasks',
            roleKeys: [],
            permissionsKey: 'MasterTask',
            icon: FaTasks
          },
          {
            display: 'Questions',
            roleKeys: [],
            permissionsKey: 'Question',
            icon: BsQuestionDiamond
          },
        ],
      },
      {
        label: 'Account',
        subItems: [
          {
            display: 'Settings',
            icon: FiSettings
          },
          {
            display: 'Logout',
            icon: HiLogout
          }
        ]
      }
    ]

    const unauthenticatedMenuItemGroups = [
      {
        label: 'Signup',
        icon: FiUserPlus
      },
      {
        label: 'Login',
        icon: HiLogin
      }
    ]

    return state.isAuthenticated ? authenticatedMenuItemGroups : unauthenticatedMenuItemGroups
  }

  const formatMenuItemId = (config: MenuItemProps) => {
    return config?.display?.toLowerCase().replace(' ', '-')
  }

  const renderMenuItemList = (items: MenuItemProps[]) => {
    if (!shouldRenderListGroup(items, state.user)) return null
    
    return items?.map((item: MenuItemProps) => (
      <div key={item.id || item.display}>
        {shouldRenderMenuItem(item, state.user) && (
          <ListItem disablePadding>
            <CustomMenuItem
              id={`drawer-menu-btn-${formatMenuItemId(item)}`}
              onClick={item?.display === 'Logout' ? handleLogout : () => {}}
              icon={item?.icon}
              to={`/${formatMenuItemId(item)}`}
              display={item?.display}
            />
          </ListItem>
        )}
      </div>
    ));
  }

  const renderList = () => {
    const items = getMenuItems()
    
    return (
      <List>
        {items?.map((config: MenuItemGroup, i: number) => (
            <div key={config.label || i}>
              {shouldRenderListGroup(config.subItems, state.user) && (
                <ListItem disablePadding>
                  {config.subItems?.length ? (
                    <List>
                      <ListItemText className="ml-3">{config.label}</ListItemText>
                      {renderMenuItemList(config.subItems)}
                    </List>
                  ): renderMenuItemList([{display: config.label, icon: config.icon}])}
                </ListItem>
              )}
            </div>
        ))}
      </List>
    );
  }

  return (
    <>
      {renderList()}
    </>
  );
}

export default DrawerMenuItems;
