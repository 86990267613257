import * as React from 'react';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Stack} from '@mui/material';
import {CiLocationOn} from 'react-icons/ci';

import {Location} from '../../state-management/types';
import {MapDirectionsButton} from '..';
import './styles.scss';

interface TripCardProps {
  title: string
  location: Location
  onConfirmArrival: () => void
}

const TripCardComponent: React.FC<TripCardProps> = ({ title, location, onConfirmArrival }) => {
  
  const footer = (
    <Stack>
      <Stack>
        <MapDirectionsButton location={location}/>
      </Stack>
      <br/>
      <Stack>
        <Button
          id="confirm-arrival-btn"
          label="Confirm Arrival"
          severity="success"
          className="p-button-rounded"
          icon="pi pi-check"
          onClick={onConfirmArrival}
        />
      </Stack>
    </Stack>
  )
  
  return (
    <div key={location?.id} className="card flex justify-content-center">
      <Card title={title} footer={footer} className="trip-card boatload--text md:w-25rem">
        <span className="trip-text">
          <CiLocationOn className='mr-2'/>
          {location?.full_address || location?.name}
        </span>
      </Card>
    </div>
  );
};

export default TripCardComponent;
