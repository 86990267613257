import { createContext } from 'react'
import { JobDispatch } from './reducer'
import {
  Answer,
  Boat,
  Job, MasterTask,
  Question,
  Task,
} from '../types'

export interface JobState {
  job?: Job
  currentBoat?: Boat
  masterTasks: MasterTask[]
  preDepartureMasterTasks: MasterTask[]
  regularMasterTasks: MasterTask[]
  postArrivalMasterTasks: MasterTask[]
  preDepartureTasks: Task[]
  preDepartureTasksDone: boolean
  regularTasks: Task[]
  regularTasksDone: boolean
  postArrivalTasks: Task[]
  postArrivalTasksDone: boolean
  currentMasterTasks: MasterTask[]
  currentMasterTask?: MasterTask
  currentMasterTaskIndex: number
  currentTask?: Task
  currentTaskIndex: number
  currentQuestion?: Question
  currentQuestionIndex: number
  
  skipReason?: string
  arrivedAtLaunch?: boolean
  completed?: boolean
  
  error: any
  loading: boolean
}

export interface JobContextState {
  state: JobState
  dispatch: JobDispatch
}

export const defaultState = (): JobState => ({
  job: undefined,
  currentBoat: undefined,
  masterTasks: [],
  currentMasterTasks: [],
  preDepartureMasterTasks: [],
  regularMasterTasks: [],
  postArrivalMasterTasks: [],
  currentMasterTask: undefined,
  currentMasterTaskIndex: 0,
  preDepartureTasks: [],
  preDepartureTasksDone: false,
  regularTasks: [],
  regularTasksDone: false,
  postArrivalTasks: [],
  postArrivalTasksDone: false,
  currentTask: undefined,
  currentTaskIndex: 0,
  currentQuestionIndex: 0,
  currentQuestion: undefined,
  arrivedAtLaunch: false,
  skipReason: '',
  completed: false,
  error: '',
  loading: false,
})

export const Context = createContext<JobContextState>({
  state: defaultState(),
  dispatch: () => null,
})
