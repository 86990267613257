import * as React from 'react';

import {Card} from 'primereact/card';
import Divider from '@mui/material/Divider';

import {TbSpeedboat} from 'react-icons/tb';
import {CiClock2} from 'react-icons/ci';
import {CiLocationOn} from 'react-icons/ci';
import {BsFillPersonVcardFill} from 'react-icons/bs';
import {RiRouteFill} from 'react-icons/ri';

import {Boat, Job} from '../../state-management/types';
import {DateTime, Grid, TruckComponent} from '../index';
import './styles.scss'

interface JobCardProps {
  job: Job
  onClick?: () => void
  disabled?: boolean | false
}

const JobCardComponent: React.FC<JobCardProps> = ({ job, onClick, disabled }) => {
  
  
  const handleOnClick = () => {
    if (!disabled && onClick && typeof onClick === 'function') {
      onClick();
    }
  }
  
  const header = (
    <div className="job-card--header">
      <CiLocationOn className='mr-2'/>
      {job?.customer?.location?.full_address || 'N/A'}
    </div>
  )
  
  const driversTemplate = (
    <div className="job-card--text">
      <h3>
        <RiRouteFill className='mr-2'/>
        {job?.job_type?.name}
      </h3>
      <h3>
        <BsFillPersonVcardFill className='mr-2'/>
        {job?.driver ? (
          <>
            {job?.driver?.full_name}
          </>
        ) : (
          <>
            Driver not assigned
          </>
        )}
      </h3>
      {job?.passenger && (
        <h3>
          <BsFillPersonVcardFill className='mr-2'/>
          {job?.passenger?.full_name}
        </h3>
      )}
    </div>
  )
  
  const boatsTemplate = (
    <div className="job-card--text">
      {job?.boats?.map((boat: Boat) => (
        <h3 key={boat?.id}>
          <TbSpeedboat className='mr-2'/>
          {boat?.name}
        </h3>
      ))}
    </div>
  )
  
  const scheduleTemplate = (
    <>
      <DateTime
        icons
        date={job?.appointment_date}
        time={job?.appointment_time}
        showTime={!job?.daily_order}
      />
      {job?.daily_order && (
        <div className="job-card--text">
          <CiClock2 className='mr-2'/>
          Daily Order: {job?.daily_order}
        </div>
      )}
    </>
  )
  
  return (
    <Card
      id='job-card'
      className='job-card'
      title={job?.customer?.full_name}
      subTitle={header}
      onClick={handleOnClick}
    >
      <Divider/>
      <Grid
        items={[
          {
            id: 'appointment',
            cols: {xs: 6, sm: 6, md: 6, lg: 6},
            content: scheduleTemplate,
            rows: 1,
          },
          {
            id: 'driver',
            cols: {xs: 6, sm: 6, md: 6, lg: 6},
            content: driversTemplate,
            rows: 1,
          },
          {
            id: 'truck',
            cols: {xs: 6, sm: 6, md: 6, lg: 6},
            content: <TruckComponent icons truck={job.truck} />,
            rows: 1,
          },
          {
            id: 'boats',
            cols: {xs: 6, sm: 6, md: 6, lg: 6},
            content: boatsTemplate,
            rows: 1,
          },
        ]}
      />
    </Card>
  );
};

export default JobCardComponent;
