import * as React from 'react';
import {MultiSelect} from 'primereact/multiselect';
import {getPlaceholder, titleCaseWords} from '../../utils';
import {formatPluralSuffix} from '../../utils/formatting';
import './styles.scss';


interface MultiSelectProps {
  label: string
  selected: any[]
  options: any[]
  setSelected: (event: any) => void
  filterBy?: string
  getName?: (option: any) => string
}

const MultiSelectComponent: React.FC<MultiSelectProps> = ({ label, filterBy, selected, options, setSelected, getName }) => {
  
  const pluralLabel = () => {
    return formatPluralSuffix(label)
  }
  
  const getDisplayName = (option: any) => {
    if (getName)
      return getName(option);
    
    if (typeof option === 'string')
      return option
    
    if (option?.name)
      return option.name
    
    if (option?.full_name)
      return option.full_name
    
    return option?.title
  }
  
  const template = (option: any) => {
    return (
      <div id={`${pluralLabel()}-item`} className="dropdown-item">
        <div id={`${pluralLabel()}-item-${option.id}`}>
          {getDisplayName(option)}
        </div>
      </div>
    );
  }
  
  const panelFooterTemplate = () => {
    const length = selected?.length ?? 0;
    
    return (
      <div id={`${pluralLabel()}-panel-footer`} className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? 's' : ''} selected.
      </div>
    );
  }
  
  const selectedTemplate = (option: any) => {
    if (option) {
      return (
        <div className={`selected-${pluralLabel()}-item selected-${pluralLabel()}-item-value`}>
          <div>{getDisplayName(option)}</div>
        </div>
      );
    }
    
    return `Select ${getPlaceholder(pluralLabel())}`
  }
  
  return (
    <MultiSelect
      id={`${pluralLabel()}-form-dropdown`}
      display="chip"
      value={selected}
      options={options}
      onChange={(e) => setSelected(e.value)}
      optionLabel={filterBy}
      placeholder={`Select ${titleCaseWords(pluralLabel())}s`}
      filter
      filterBy={filterBy}
      className="multiselect-form-input"
      itemTemplate={template}
      selectedItemTemplate={selectedTemplate}
      panelFooterTemplate={panelFooterTemplate}
    />
  );
};

export default MultiSelectComponent;
