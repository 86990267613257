import * as React from 'react';
import {Accordion, AccordionTab} from 'primereact/accordion';

import {MasterTask} from '../../state-management/types';
import {useJob} from '../../state-management';
import {TaskComponent} from '..';
import './styles.scss';


const TaskList: React.FC = () => {
  
  const {
    state,
    goToTask,
  } = useJob()
  
  const onTabChange = (event: any) => {
    const index = event.index
    goToTask(index).catch((error: any) => console.log(error))
  }
  
  const headerTemplate = (options: any) => {
    return (
      <div className="task-header">
        <strong className="mr-2">{options.children.props.masterTask?.name}</strong>
        {/*<h4>{options.children.props.masterTask?.description}</h4>*/}
      </div>
    )
  }
  
  return (
    <>
      <Accordion onTabChange={onTabChange} activeIndex={state.currentMasterTaskIndex}>
        {state.currentMasterTasks?.map((masterTask: MasterTask) => (
          <AccordionTab disabled={false} key={masterTask?.id} headerTemplate={headerTemplate}>
            <TaskComponent masterTask={masterTask} />
          </AccordionTab>
        ))}
      </Accordion>
    </>
  );
}

export default TaskList
