import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Chart } from 'primereact/chart';
import {useQuery} from '@apollo/client';

import {GET_JOB_STATS} from '../../hooks/useJobs';
import {useAuth} from '../../state-management';
import {
  BoatType,
  Customer,
  JobStats, JobType, Marina, Truck, User,
} from '../../state-management/types';
import './styles.scss'


interface JobStatsDashboardProps {
  marinas?: Marina[];
  users?: User[];
  customers?: Customer[];
  jobTypes?: JobType[];
  trucks?: Truck[];
  boatTypes?: BoatType[];
  startDate?: string;
  endDate?: string;
}

const JobStatsDashboard: React.FC<JobStatsDashboardProps> = ({
  marinas,
  users,
  customers,
  boatTypes,
  trucks,
  startDate,
  endDate,
}) => {
  const defaultStartDate = dayjs().subtract(14, 'day').format('YYYY-MM-DD');
  const defaultEndDate = dayjs().add(14, 'day').format('YYYY-MM-DD');
  
  const [jobStats, setJobStats] = useState<JobStats>();
  
  useQuery(GET_JOB_STATS, {
    variables: {
      params: {
        user_ids: users?.length ? users?.map(user => user?.id) : [],
        customer_ids: customers?.length ? customers?.map(customer => customer?.id) : [],
        boat_type_ids: boatTypes?.length ? boatTypes?.map(boatType => boatType?.id) : [],
        truck_ids: trucks?.length ? trucks?.map(truck => truck?.id) : [],
        marina_ids: marinas?.length ? marinas?.map(marina => marina?.id) : [],
        start_time: startDate ?? defaultStartDate,
        end_time: endDate ?? defaultEndDate,
      }
    },
    onCompleted: ({ job_stats: res }) => {
      setJobStats(res);
    }
  });
  
  const getBasicOptions = () => {
    const stepSize = 1
    return {
      maintainAspectRatio: false,
      aspectRatio: .6,
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057',
            stepSize: stepSize
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }
  };
  
  return (
    <div className="stats-timeline justify-content-center">
      <Chart type="line" data={JSON.parse(JSON.stringify(jobStats?.timeline_data || {}))} options={getBasicOptions()} />
    </div>
  );
};

export default JobStatsDashboard;
