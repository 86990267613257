import { Dispatch } from 'react'
import {JobState} from './context'
import {Answer, Job, MasterTask, Question, Task} from '../types';

export const START_JOB = 'START_JOB' as const
export const SKIP_JOB = 'SKIP_JOB' as const
export const ARRIVED_AT_LAUNCH = 'ARRIVED_AT_LAUNCH' as const
export const SUBMIT_ANSWER = 'SUBMIT_ANSWER' as const
export const COMPLETE_TASK = 'COMPLETE_TASK' as const
export const GO_TO_TASK = 'GO_TO_TASK' as const
export const GO_TO_QUESTION = 'GO_TO_QUESTION' as const
export const COMPLETE_JOB = 'COMPLETE_JOB' as const
export const ERROR_JOB = 'ERROR_JOB' as const

export type JobAction =
  | { type: 'START_JOB', job: Job }
  | { type: 'SKIP_JOB', skipReason: string }
  | { type: 'ARRIVED_AT_LAUNCH' }
  | { type: 'ADD_TASK', task: Task }
  | { type: 'COMPLETE_TASK', task: Task }
  | { type: 'SUBMIT_ANSWER', question: Question, answer: Answer }
  | { type: 'GO_TO_TASK', index: number }
  | { type: 'GO_TO_QUESTION', index: number }
  | { type: 'COMPLETE_JOB' }
  | { type: 'ERROR_JOB'; error: string }

export type JobDispatch = Dispatch<JobAction>

const findFirstIncompleteMasterTask = (masterTasks: MasterTask[], tasks?: Task[]): MasterTask | undefined => {
  return masterTasks?.find((t: MasterTask) => !tasks?.find((task: Task) => task?.master_task?.id === t?.id && task?.completed_at))
}

const findFirstUnansweredQuestionIndex = (masterTask?: MasterTask, task?: Task): number => {
  const questions = masterTask?.questions
  if (!questions) return 0;
  
  const answers = task?.answers
  if (!answers) return 0
  
  return questions?.findIndex((q: Question) => !answers?.find((a: Answer) => a.question_id === q.id)) ?? 0
}

export const reducer = (
  state: JobState,
  action: JobAction
): JobState => {
  switch (action.type) {
    case 'START_JOB': {
      const { job } = action
      
      const masterTasks: MasterTask[] = [];
      job?.filtered_boats?.forEach((boat) => {
        masterTasks.push(...boat?.boat_type?.master_tasks?.filter((t) => t.questions?.length) ?? []);
      });
      
      const preDepartureMasterTasks = masterTasks?.filter(
        (t) => t.is_predeparture
      ) ?? [];
      
      const regularMasterTasks = masterTasks?.filter(
        (t) => !t.is_predeparture && !t.is_postarrival
      )
      
      const postArrivalMasterTasks = masterTasks?.filter(
        (t) => t.is_postarrival
      ) ?? [];
      
      const tasks = job?.tasks
      
      const preDepartureTasks = tasks?.filter(
        (task: Task) => task?.master_task?.is_predeparture
      ) ?? [];
      
      const preDepartureTasksDone = preDepartureMasterTasks?.every(
        (masterTask: MasterTask) =>
          tasks?.find((task: Task) => (task?.master_task?.id === masterTask?.id) && task?.completed_at) !== undefined
      );
      
      const regularTasks = tasks?.filter(
        (task: Task) => !task?.master_task?.is_predeparture && !task?.master_task?.is_postarrival
      ) ?? [];
      
      const regularTasksDone = regularMasterTasks?.every(
        (masterTask: MasterTask) =>
          tasks?.find((task: Task) => (task?.master_task?.id === masterTask?.id) && task?.completed_at) !== undefined
      )
      
      const postArrivalTasks = tasks?.filter(
        (task: Task) => task?.master_task?.is_postarrival
      ) ?? [];
      
      const postArrivalTasksDone = postArrivalMasterTasks?.every(
        (masterTask: MasterTask) =>
          tasks?.find((task: Task) => (task?.master_task?.id === masterTask?.id) && task?.completed_at) !== undefined
      )
      
      let currentMasterTasks: MasterTask[] = []
      if (!preDepartureTasksDone) {
        currentMasterTasks = preDepartureMasterTasks
      } else if (!regularTasksDone) {
        currentMasterTasks = regularMasterTasks
      } else if (!postArrivalTasksDone) {
        currentMasterTasks = postArrivalMasterTasks
      }
      
      let currentMasterTaskIndex = 0
      let currentMasterTask: MasterTask | undefined
      currentMasterTask = findFirstIncompleteMasterTask(currentMasterTasks, tasks)
      currentMasterTaskIndex = currentMasterTasks?.findIndex((masterTask: MasterTask) => masterTask?.id === currentMasterTask?.id) ?? 0
      
      if (!currentMasterTask) {
        currentMasterTask = findFirstIncompleteMasterTask(masterTasks, tasks)
      }
      
      let currentTaskIndex = 0
      let currentTask
      currentTaskIndex = tasks?.findIndex((task: Task) => task?.master_task?.id === currentMasterTask?.id) ?? 0
      
      currentTask = tasks?.find((task: Task) => task?.master_task?.id === currentMasterTask?.id)
      
      let currentQuestionIndex = 0
      if (currentTaskIndex >= 0) {
        currentQuestionIndex = findFirstUnansweredQuestionIndex(currentMasterTask, currentTask) ?? 0
      } else {
        currentTaskIndex = 0
        currentQuestionIndex = 0
      }
      
      let currentQuestion = currentMasterTask?.questions?.[currentQuestionIndex]
      
      return { ...state,
        job,
        currentBoat: job?.filtered_boats ? job.filtered_boats[0] : undefined,
        currentMasterTasks,
        currentMasterTask,
        currentMasterTaskIndex,
        currentTask,
        currentTaskIndex,
        masterTasks,
        preDepartureMasterTasks,
        regularMasterTasks,
        postArrivalMasterTasks,
        preDepartureTasks,
        preDepartureTasksDone,
        regularTasks,
        regularTasksDone,
        postArrivalTasks,
        postArrivalTasksDone,
        currentQuestionIndex,
        currentQuestion,
        arrivedAtLaunch: Boolean(job?.arrived_at_pickup),
        error: null,
        loading: true
      }
    }
    
    case 'SKIP_JOB': {
      const { skipReason } = action
      return {
        ...state,
        job: {
          ...state.job,
          skip_reason: skipReason
        },
      }
    }
    
    case 'ARRIVED_AT_LAUNCH': {
      return {
        ...state,
        arrivedAtLaunch: true,
      }
    }
    
    case 'ADD_TASK': {
      const { task } = action
      return {
        ...state,
        currentTask: task,
        job: { ...state.job, tasks: [...state.job?.tasks ?? [], task] }
      }
    }
    
    case 'GO_TO_TASK': {
      const { index } = action
      
      let currentQuestionIndex = 0
      let currentMasterTask = state.currentMasterTasks[index]
      let currentTask = state.job?.tasks?.find((task: Task) => task?.master_task?.id === currentMasterTask?.id)
      return {
        ...state,
        currentMasterTaskIndex: index,
        currentMasterTask,
        currentTaskIndex: index,
        currentTask,
        currentQuestionIndex
      }
    }
    
    case 'GO_TO_QUESTION': {
      const { index } = action
      let currentQuestionIndex = index
      let currentQuestion = state.currentQuestion
      currentQuestion = state.currentMasterTask?.questions?.[currentQuestionIndex]
      return { ...state, currentQuestionIndex, currentQuestion }
    }
    
    case 'SUBMIT_ANSWER': {
      if (!state.job) return state
      
      const { question, answer } = action
      let job = state.job
      let tasks = job?.tasks ?? []
      let task = state.currentTask
      let answers = task?.answers
      
      if (!answers) {
        answers = [answer]
      } else {
        const answerIndex = answers?.findIndex((a) => a.question_id === question.id)
        if (answerIndex > -1) {
          answers = answers?.map((a, i) => i === answerIndex ? answer : a)
        } else {
          answers = [...answers, answer]
        }
      }
      
      task = { ...task, answers, answer_count: answers?.length ?? 0 }
      tasks = tasks?.map((t: Task) => task && t?.id === task?.id ? task : t) ?? []
      let currentTask: Task | undefined = { ...task, answers }
      
      let currentMasterTask = state.currentMasterTask
      let currentMasterTasks = state.currentMasterTasks
      let currentMasterTaskIndex = state.currentMasterTaskIndex
      let currentTaskIndex = state.currentTaskIndex
      let currentQuestionIndex = state.currentQuestionIndex + 1
      
      let preDepartureTasksDone = state.preDepartureTasksDone
      let regularTasksDone = state.regularTasksDone
      let postArrivalTasksDone = state.postArrivalTasksDone
      
      if (currentQuestionIndex === currentMasterTask?.questions?.length) {
        const numAnswers = answers?.length ?? 0
        const numQuestions = currentMasterTask?.questions?.length ?? 0
        if (numAnswers < numQuestions) {
          currentQuestionIndex = findFirstUnansweredQuestionIndex(currentMasterTask, currentTask)
        } else {
          currentQuestionIndex = 0
          currentMasterTaskIndex += 1
          currentTaskIndex += 1
          if (currentMasterTaskIndex === currentMasterTasks?.length) {
            currentMasterTaskIndex = 0
            if (!state.preDepartureTasksDone) {
              preDepartureTasksDone = true
              currentMasterTasks = state.regularMasterTasks
            } else if (!state.regularTasksDone) {
              regularTasksDone = true
              currentMasterTasks = state.postArrivalMasterTasks
            } else if (!state.postArrivalTasksDone) {
              postArrivalTasksDone = true
              currentMasterTasks = []
              currentMasterTask = undefined
              currentMasterTaskIndex = -1
            }
          }
          
          currentTask = undefined
        }
      }
      
      currentMasterTask = currentMasterTasks?.[currentMasterTaskIndex]
      let currentQuestion = currentMasterTask?.questions?.[currentQuestionIndex]
      
      return {
        ...state,
        job: { ...job, tasks },
        preDepartureTasksDone,
        regularTasksDone,
        postArrivalTasksDone,
        currentMasterTasks,
        currentMasterTask,
        currentMasterTaskIndex,
        currentTaskIndex,
        currentTask,
        currentQuestionIndex,
        currentQuestion,
        loading: false
      }
    }
    
    case 'COMPLETE_TASK': {
      const { task } = action
      const currentTask = state.job?.tasks?.find((t: Task) => t?.id === task?.id)
      return {
        ...state,
        currentTask: { ...currentTask, completed_at: task.completed_at },
        loading: false,
      }
    }
    
    case 'COMPLETE_JOB': {
      return { ...state, completed: true, loading: false }
    }

    case 'ERROR_JOB': {
      const { error } = action
      return { ...state, error, loading: false }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
