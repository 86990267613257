import { useQuery, useMutation, gql } from '@apollo/client';

const GET_MARINAS = gql`
    query GetMarinas {
        marinas {
            id
            name
            phone
            num_trucks
            num_customers
            num_boats
            num_jobs
            location {
                id
                full_address
                address
                city
                state
                zipcode
            }
            created_at
        }
    }
`



const UPSERT_MARINA = gql`
    mutation UpsertMarina($input: MarinaInput!) {
        upsertMarina(input: $input) {
            id
            name
            phone
            num_trucks
            num_customers
            num_boats
            num_jobs
            location {
                id
                full_address
                address
                city
                state
                zipcode
            }
            created_at
        }
    }
`

const DELETE_MARINA = gql`
    mutation DeleteMarina($id: ID!) {
        deleteMarina(id: $id)
    }
`


export function useMarinas() {
  const { data, error, loading } = useQuery(GET_MARINAS, {
  
  })
  
  const [upsertMarinaMutation] = useMutation(UPSERT_MARINA, { refetchQueries: [GET_MARINAS] })
  const [deleteMarinaMutation] = useMutation(DELETE_MARINA, { refetchQueries: [GET_MARINAS] })
  
  const marinas = data?.marinas ?? []
  
  const upsertMarina = async (input: any) => {
    const res = await upsertMarinaMutation({ variables: { input } })
    return res?.data?.upsertMarina ?? {}
  }
  
  const deleteMarina = async (id: number) => {
    const res = await deleteMarinaMutation({ variables: { id } })
    return res?.data?.deleteMarina ?? res
  }
  
  return {
    marinas,
    upsertMarina,
    deleteMarina,
    error,
    loading
  }
}
