import { useQuery, useMutation, gql } from '@apollo/client';
import {API_URL} from '../utils';


const GET_ANSWERS = gql`
    query GetAnswers {
        answers {
            id
            question_id
            question {
                id
                title
                type
            }
            answer
            signed_url
            created_at
            updated_at
        }
    }
`

export const GET_ANSWER = gql`
    query GetAnswer($id: ID!) {
        answer(id: $id) {
            id
            question {
                id
                title
                type
            }
            answer
            signed_url
            created_at
            updated_at
        }
    }
`

const UPSERT_ANSWER = gql`
    mutation UpsertAnswer($input: AnswerInput!) {
        upsertAnswer(input: $input) {
            id
            answer
            signed_url
            question_id
            question {
                id
                title
                type
            }
            created_at
            updated_at
        }
    }
`

const DELETE_ANSWER = gql`
    mutation DeleteAnswer($id: ID!) {
        deleteAnswer(id: $id)
    }
`


export function useAnswers(id?: number) {
  const answerRes = useQuery(GET_ANSWER, { skip: !id, variables: { id } })
  
  const { data, error, loading } = useQuery(GET_ANSWERS)
  
  const [upsertAnswerMutation] = useMutation(UPSERT_ANSWER, { refetchQueries: [{ query: GET_ANSWERS }] })
  const [deleteAnswerMutation] = useMutation(DELETE_ANSWER, { refetchQueries: [{ query: GET_ANSWERS }] })
  
  const answers = data?.answers ?? []
  
  const uploadFile = async (file: any, taskId: number, questionId: number) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    };
    
    const body = new FormData();
    body.append('path', `jobs/v2/${taskId}/${questionId}`);
    // @ts-ignore
    body.append('file', file);
    // @ts-ignore
    body.append('task_id', taskId);
    // @ts-ignore
    body.append('question_id', questionId);
    
    const res = await fetch(`${API_URL}upload`, {
      method: 'POST',
      headers,
      body,
    });
    if (!res.ok) {
      throw Error();
    }
    
    return res.json();
  }
  
  const upsertAnswer = async (input: any) => {
    const res = await upsertAnswerMutation({ variables: { input } })
    return res?.data?.upsertAnswer ?? {}
  }
  
  const deleteAnswer = async (id: number) => {
    const res = await deleteAnswerMutation({ variables: { id } })
    return res?.data?.deleteAnswer ?? res
  }
  
  return {
    answerRes: answerRes?.data?.answer ?? {},
    answers,
    upsertAnswer,
    deleteAnswer,
    uploadFile,
    answersError: error,
    loadingAnswers: loading
  }
}
