import { useContext } from 'react';

import {Context, JobState} from './context';
import {Answer, Job, MasterTask, Question, Task} from '../types';
import {useAnswers} from '../../hooks/useAnswers';
import {useTasks} from '../../hooks/useTasks';
import {useJobs} from '../../hooks/useJobs';

export interface UseJob {
  (): {
    startJob: (job: Job) => Promise<void>;
    skipJob: (skipReason: string) => Promise<Job>;
    markReturnInProgress: () => Promise<void>;
    addTask: (masterTask: MasterTask) => Promise<Task>;
    goToTask: (index: number) => Promise<void>;
    goToQuestion: (index: number) => Promise<void>;
    submitAnswer: (task: Task, question: Question, selectedAnswer: string, answer?: Answer, image?: File) => Promise<Answer>;
    completeTask: (task: Task) => Promise<Task>;
    completeJob: () => Promise<void>;

    state: JobState;
  };
}

export const useJob: UseJob = () => {
  const { state, dispatch } = useContext(Context);
  
  const {upsertJob} = useJobs()
  const {upsertTask} = useTasks()
  const {upsertAnswer, uploadFile} = useAnswers()

  const startJob = async (job: Job): Promise<any> => {
    dispatch({
      type: 'START_JOB',
      job: job,
    });
  };
  
  const skipJob = async (skipReason: string) => {
    const job = await upsertJob({
      id: state.job?.id,
      skip_reason: skipReason,
    }).catch((err) => console.error(err))
    
    if (job)
      dispatch({
        type: 'SKIP_JOB',
        skipReason,
      })
    else
      dispatch({
        type: 'ERROR_JOB',
        error: 'Unable to skip job'
      })
    
    return job
  }
  
  const markReturnInProgress = async () => {
    const job = await upsertJob({
      id: state.job?.id,
      arrived_at_pickup: true
    }).catch((err) => console.error(err))
    
    if (job)
      dispatch({
        type: 'ARRIVED_AT_LAUNCH'
      })
    else
      dispatch({
        type: 'ERROR_JOB',
        error: 'Unable to mark return in progress'
      })
    
    return job
  }
  
  const addTask = async (masterTask: MasterTask) => {
    if (!masterTask?.id)
      throw new Error('Missing master task ID')
    
    const task = await upsertTask({
      job_id: state.job?.id,
      boat_id: state.currentBoat?.id,
      master_task_id: masterTask?.id,
    }).catch((err) => {
      console.error(err)
      dispatch({
        type: 'ERROR_JOB',
        error: 'Unable to add task'
      })
    })
    
    if (!task)
      throw new Error('Unable to add task')
    
    dispatch({
      type: 'ADD_TASK',
      task: task
    })
    
    return task
  }
  
  const completeTask = async (task: Task) => {
    if (!task?.id)
      throw new Error('Missing task id')
    
    task = await upsertTask({
      id: task?.id,
      completed_at: new Date()
    }).catch((e: any) => {
      console.error(e)
      dispatch({
        type: 'ERROR_JOB',
        error: 'Unable to mark task as completed'
      })
    })
    
    if (!task)
      throw new Error('Unable to complete task')
    
    dispatch({
      type: 'COMPLETE_TASK',
      task: task
    })
    
    return task
  }
  
  const submitAnswer = async (task: Task, question: Question, selectedAnswer: string, answer?: Answer, image?: File): Promise<any> => {
    if (!task?.id)
      throw new Error('Missing task id')
    if (!question?.id)
      throw new Error('Missing question id')
    if (!selectedAnswer && !image)
      throw new Error('Missing answer')
    
    let answerRes
    if (image) {
      const res = await uploadFile(image, task?.id ?? 0, question?.id).catch((e: any) => console.error(e))
      if (res?.answer) {
        answerRes = res.answer
        answerRes.signed_url = res.signed_url
      }
    } else {
      answerRes = await upsertAnswer({
        id: answer?.id,
        task_id: task?.id,
        question_id: question?.id,
        answer: selectedAnswer,
      }).catch((e: any) => {
        console.error(e)
        dispatch({
          type: 'ERROR_JOB',
          error: 'Unable to submit answer'
        })
      })
    }
    
    const numAnswers = (state.currentTask?.answers?.length ?? 0) + 1
    const numQuestions = state.currentMasterTask?.questions?.length ?? 0
    const allQuestionsAnswered = numAnswers >= numQuestions
    if (allQuestionsAnswered) {
      await completeTask(task)
    }
    
    await dispatch({
      type: 'SUBMIT_ANSWER',
      question: question,
      answer: answerRes
    });
    
    return answerRes
  }
  
  const goToTask = async (index: number): Promise<any> => {
    dispatch({
      type: 'GO_TO_TASK',
      index
    });
  }
  
  const goToQuestion = async (index: number): Promise<any> => {
    dispatch({
      type: 'GO_TO_QUESTION',
      index: index
    });
  }
  
  const completeJob = async (): Promise<any> => {
    const res = await upsertJob({
      id: state.job?.id,
      completed_at: new Date(),
      return_in_progress: false
    })
    
    if (res)
      dispatch({
        type: 'COMPLETE_JOB',
      });
    else
      dispatch({
        type: 'ERROR_JOB',
        error: 'Unable to complete job'
      });
    
    return res
  }
  
  return {
    startJob,
    skipJob,
    markReturnInProgress,
    addTask,
    completeTask,
    submitAnswer,
    goToTask,
    goToQuestion,
    completeJob,
    state,
  };
};
