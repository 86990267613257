import * as React from 'react';
import {useQuery} from '@apollo/client';

import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';

import {
  Answer,
  Question,
} from '../../state-management/types';
import {useJob} from '../../state-management';
import {GET_ANSWER} from '../../hooks/useAnswers';

import FileField from '../file-field';
import {Loader, MapDirectionsButton} from '..';
import './styles.scss';

interface QuestionComponentProps {
  question: Question
  answer?: Answer
  onSave?: (question: Question, selectedAnswer: string, answer?: Answer, image?: File) => Promise<any>
  index?: number
  readOnly?: boolean
}

const QuestionComponent: React.FC<QuestionComponentProps> = ({index, question, onSave, answer, readOnly}) => {
  const toast = React.useRef(null);
  
  const [selectedAnswer, setSelectedAnswer] = React.useState<string>(answer?.answer ?? '')
  const [image, setImage] = React.useState<File>()
  const [signedUrl, setSignedUrl] = React.useState<string>(answer?.signed_url ?? '')
  const [loading, setLoading] = React.useState(false)
  
  const {state} = useJob()
  
  const answerRes = useQuery(GET_ANSWER, {
    variables: { id: answer?.id },
    skip: !answer?.id,
    onCompleted: (data) => {
      if (data?.answer?.signed_url) setSignedUrl(data?.answer?.signed_url)
      if (data?.answer?.answer) setSelectedAnswer(data?.answer?.answer)
    },
    onError: (err) => {
      // @ts-ignore
      toast.current?.show({severity: 'error', summary: 'Error', detail: 'Retrieving Answer', life: 3000});
    }
  })
  
  const onFileSelect = (files: any) => {
    if (files?.length)
      setImage(files[0])
  }
  
  const onSubmit = async () => {
    setLoading(true)
    if (onSave)
      await onSave(question, selectedAnswer, answer, image)
    setLoading(false)
  }
  
  const saveButton = (
    <Button
      id="save-answer-btn"
      disabled={!selectedAnswer || String(answer?.answer) === selectedAnswer}
      label={answer?.id ? 'Update' : 'Save'}
      icon="pi pi-plus"
      className={`p-button-${answer?.id ? 'success' : 'warning'} mr-2`}
      onClick={onSubmit}
    />
  )
  
  return (
    <div id="question-component" key={question?.id} className="question-card-body">
      <Toast ref={toast}/>
      <h4 className="question-title">
        {index ? `${index}. ` : null} {question?.title}
        <Loader loading={answerRes.loading || loading} />
      </h4>
      
      <br/>
      
      {question?.type === 'MULTIPLE_CHOICE' && (
        <>
          <div className="card flex justify-content-center">
            <div className="flex flex-column gap-3">
              {question?.possible_answers?.split(',').map((answerVal, i) => {
                return (
                  <div key={answerVal} className="question-radio-option flex align-items-center">
                    <RadioButton
                      data-cy={`radio-option-${i}`}
                      inputId={answerVal}
                      name="possible-answer"
                      value={answerVal}
                      disabled={false}
                      onChange={(e) =>{
                        setSelectedAnswer(e.value)
                      }}
                      checked={selectedAnswer === answerVal}
                    />
                    <label htmlFor={answerVal} className="ml-2">{answerVal}</label>
                  </div>
                );
              })}
              {saveButton}
            </div>
          </div>
        </>
      )}
      
      {question?.type === 'BOOLEAN' && (
        <>
          <div className="card flex justify-content-center">
            <div className="flex flex-column gap-3">
              <div className="question-radio-option flex align-items-center">
                <RadioButton
                  data-cy="radio-option-1"
                  inputId="yes"
                  name="possible-answer"
                  value="Yes"
                  disabled={false}
                  onChange={(e) => setSelectedAnswer(e.value)}
                  checked={selectedAnswer === 'Yes'}
                />
                <label htmlFor="yes" className="ml-2">Yes</label>
              </div>
              <div className="question-radio-option flex align-items-center">
                <RadioButton
                  data-cy="radio-option-2"
                  inputId="no"
                  name="possible-answer"
                  value="No"
                  disabled={false}
                  onChange={(e) => setSelectedAnswer(e.value)}
                  checked={selectedAnswer === 'No'}
                />
                <label htmlFor="no" className="ml-2">No</label>
              </div>
              {saveButton}
            </div>
          </div>
        </>
      )}
      
      {question?.type === 'TEXT' && (
        <>
          <div className="card flex justify-content-center">
            <div className="flex flex-column gap-3">
              <div className="flex align-items-center">
                <InputTextarea
                  id="question-input-textarea"
                  autoResize
                  value={selectedAnswer}
                  onChange={(e) => setSelectedAnswer(e.target.value)}
                  rows={5}
                  cols={30}
                />
              </div>
              {saveButton}
            </div>
          </div>
        </>
      )}
      
      {question?.type === 'PHOTO' && (
        <div className="card flex justify-content-center">
          <div className="flex flex-column gap-3">
            <div className="flex align-items-center">
              <FileField fileUrl={signedUrl} onUpload={onSubmit} setFiles={onFileSelect}/>
            </div>
          </div>
        </div>
      )}
      
      {question?.type === 'TRIP' && (
        <div className="card flex justify-content-center">
          <div className="flex flex-column gap-3">
            <div className="flex align-items-center">
              <MapDirectionsButton location={state.job?.customer?.location}/>
              <Button
                id="confirm-arrival-btn"
                label="Confirm Arrival"
                icon="pi pi-plus"
                className="p-button-rounded p-button-success mr-2"
                onClick={() => {
                  if (onSave) onSave(question, 'Arrived', answer, image)
                }}
              />
            </div>
          </div>
        </div>
      )}
      
    </div>
  )
};

export default QuestionComponent;
