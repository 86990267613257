import * as React from 'react';
import {Toast} from 'primereact/toast';
import Carousel from 'react-material-ui-carousel'

import {Answer, MasterTask, Question, Task} from '../../state-management/types';
import {useJob} from '../../state-management';
import {ProgressCard, QuestionComponent} from '..';
import './styles.scss';


interface TaskProps {
  masterTask: MasterTask
}

const TaskComponent: React.FC<TaskProps> = ({masterTask}) => {
  
  const toast = React.useRef(null);
  
  const {
    state,
    goToQuestion,
    addTask,
    submitAnswer,
    completeTask,
  } = useJob()
  
  const [task, setTask] = React.useState<Task>(state.currentTask as Task)
  
  React.useEffect(() => {
    if (!state.currentTask) {
      addTask(masterTask).then((task) => setTask(task))
    }
  }, [state.currentTask?.answers?.length]) // eslint-disable-line
  
  const onQuestionSave = async (question: Question, selectedAnswer: string, answer?: Answer, image?: File) => {
    if (!task?.id) {
      // @ts-ignore
      toast.current?.show({severity: 'error', summary: 'Error', detail: 'Missing Task', life: 3000});
      return
    }
    
    const res = await submitAnswer(task, question, selectedAnswer, answer, image).catch((err) => {
      // @ts-ignore
      toast.current?.show({severity: 'error', summary: 'Error', detail: 'Submitting Answer', life: 3000});
    })
    
    if (res?.id) {
      // @ts-ignore
      toast.current?.show({severity: 'success', summary: 'Success', detail: 'Answer Saved', life: 3000});
      if (state.currentTask && !state.currentTask.completed_at && state.currentTask?.answers?.length === state.currentMasterTask?.questions?.length) {
        let taskRes = await completeTask(state.currentTask).catch((err) => console.log('COMPLETE TASK ERROR', err))
        if (taskRes?.id) {
          // @ts-ignore
          toast.current?.show({severity: 'success', summary: 'Success', detail: 'Task Completed', life: 3000});
        }
      }
    }
    
  }
  
  const numQuestions = masterTask.questions?.length ?? 0
  
  const progress = () => {
    let task = state.job?.tasks?.find((task: Task) => {
      return task?.master_task?.id === masterTask?.id
    })
    
    const numAnswers = task?.answers?.length ?? 0
    
    return (
      <div className="task-text">
        <ProgressCard
          numerator={numAnswers}
          denominator={numQuestions}
          showRatio
          color='auto'
        />
      </div>
    )
  }
  
  const onNavigation = (index?: number) => {
    return goToQuestion(index ?? 0)
  }
  
  const carouselTemplate = (masterTask: MasterTask) => {
    return (
      <>
        <Carousel
          index={state.currentQuestionIndex}
          animation="slide"
          navButtonsAlwaysVisible={numQuestions > 1}
          next={onNavigation}
          prev={onNavigation}
          autoPlay={false}
          swipe={true}
          indicators={false}
        >
          {masterTask?.questions?.map( (item, index) => (
            <QuestionComponent
              index={index + 1}
              key={item.id}
              onSave={onQuestionSave}
              question={item}
              answer={state.currentTask?.answers?.find((a: Answer) => a?.question_id === item.id)}
            />
          ))}
        </Carousel>
        {progress()}
      </>
    )
  }
  
  return (
    <div className="task-container">
      <Toast ref={toast}/>
      {carouselTemplate(masterTask)}
    </div>
  );
}

export default TaskComponent
