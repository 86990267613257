import { useQuery, useMutation, gql } from '@apollo/client';
import {AxiosResponse} from 'axios/index';
import {postData} from '../state-management';
import {GET_USER} from './useUsers';

export const GET_JOBS = gql`
    query GetJobs($params: JobFilterParams) {
        jobs(params: $params) {
            id
            job_type {
                id
                name
            }
            appointment
            appointment_date
            appointment_time
            daily_order
            created_at
            completed_at
            status
            arrived_at_pickup
            skips {
                reason
                skipped_at
            }
            marina {
                id
                name
            }
            pickup_location {
                id
                full_address
                latitude
                longitude
            }
            launch_location {
                id
                name
                latitude
                longitude
            }
            dropoff_location {
                id
                full_address
                latitude
                longitude
            }
            customer {
                id
                full_name
                primary_phone
                created_at
                location {
                    id
                    full_address
                    address
                    city
                    state
                    zipcode
                }
            }
            boats {
                id
                name
                short_description
                boat_type {
                    id
                    name
                    master_tasks {
                        id
                        name
                        description
                        is_predeparture
                        is_postarrival
                        questions {
                            id
                            possible_answers
                            title
                            type
                        }
                        created_at
                        updated_at
                    }
                }
            }
            driver {
                id
                full_name
                phone
            }
            passenger {
                id
                full_name
                phone
            }
            tasks {
                id
                updated_at
                created_at
                completed_at
                skipped
                in_progress
                answer_count
                answers {
                    id
                    answer
                    question_id
                    question {
                        id
                        title
                        type
                        possible_answers
                    }
                }
                boat {
                    id
                }
                master_task {
                    id
                    name
                    question_count
                }
            }
            truck {
                id
                name
                license_plate
            }
        }
    }
`;


export const GET_JOB = gql`
    query GetJob($id: ID!) {
        job(id: $id) {
            id
            daily_order
            job_type {
                id
                name
            }
            appointment
            appointment_date
            appointment_time
            arrival_instructions
            created_at
            completed_at
            status
            arrived_at_pickup
            marina {
                id
                name
            }
            filtered_boats {
                id
                name
                short_description
                boat_type {
                    id
                    name
                    master_tasks {
                        id
                        name
                        description
                        is_predeparture
                        is_postarrival
                        question_count
                        questions {
                            id
                            possible_answers
                            title
                            type
                        }
                        created_at
                        updated_at
                    }
                }
            }
            pickup_location {
                id
                full_address
                latitude
                longitude
            }
            launch_location {
                id
                name
                latitude
                longitude
            }
            dropoff_location {
                id
                full_address
                latitude
                longitude
            }
            customer {
                id
                full_name
                primary_phone
                created_at
                location {
                    id
                    full_address
                    address
                    city
                    state
                    zipcode
                }
            }
            driver {
                id
                full_name
                phone
            }
            passenger {
                id
                full_name
                phone
            }
            tasks {
                id
                updated_at
                created_at
                completed_at
                skipped
                in_progress
                answer_count
                answers {
                    id
                    answer
                    question_id
                    question {
                        id
                        title
                        type
                        possible_answers
                    }
                }
                boat {
                    id
                }
                master_task {
                    id
                    name
                    is_predeparture
                    is_postarrival
                }
            }
            truck {
                id
                name
                license_plate
            }
        }
    }
`;


export const GET_JOB_STATS = gql`
    query GetJobStats($params: JobFilterParams) {
        job_stats(params: $params) {
            num_unassigned
            num_assigned
            num_in_progress
            timeline_data {
                labels
                datasets {
                    label
                    borderColor
                    fill
                    tension
                    data
                }
            }
        }
    }
`;


const UPSERT_JOB = gql`
    mutation UpsertJob($input: JobInput!) {
        upsertJob(input: $input) {
            id
            appointment
            appointment_date
            appointment_time
            daily_order
            status
            return_in_progress
            arrived_at_pickup
            completed_at
            job_type {
                id
                name
            }
            marina {
                id
                name
            }
            customer {
                id
                full_name
                primary_phone
            }
            boats {
                id
                name
                short_description
                boat_type {
                    id
                    name
                    master_tasks {
                        id
                        name
                        description
                        is_predeparture
                        is_postarrival
                        questions {
                            id
                            possible_answers
                            title
                            type
                        }
                        created_at
                        updated_at
                    }
                }
            }
            filtered_boats {
                id
                name
                short_description
                boat_type {
                    id
                    name
                    master_tasks {
                        id
                        name
                        description
                        is_predeparture
                        is_postarrival
                        question_count
                        questions {
                            id
                            possible_answers
                            title
                            type
                        }
                        created_at
                        updated_at
                    }
                }
            }
            pickup_location {
                id
                full_address
                latitude
                longitude
            }
            launch_location {
                id
                name
                latitude
                longitude
            }
            dropoff_location {
                id
                full_address
                latitude
                longitude
            }
            driver {
                id
                full_name
                phone
            }
            passenger {
                id
                full_name
                phone
            }
            tasks {
                id
                updated_at
                created_at
                completed_at
                skipped
                in_progress
                answer_count
                answers {
                    id
                    answer
                    question_id
                    question {
                        id
                        title
                        type
                        possible_answers
                    }
                }
                boat {
                    id
                }
                master_task {
                    id
                    name
                    question_count
                    is_predeparture
                    is_postarrival
                }
            }
            truck {
                id
                name
                license_plate
            }
        }
    }
`

const DELETE_JOB = gql`
    mutation DeleteJob($id: ID!) {
        deleteJob(id: $id)
    }
`

export interface JobFilterParams {
  marina_id?: number
  job_type_id?: number
  boat_type_id?: number
  driver_id?: number
  customer_id?: number
  truck_id?: number
  start_date?: string
  appointment_date?: string
  end_date?: string
  completed?: boolean
}


export function useJobs(params?: JobFilterParams) {
  const { data, error, loading } = useQuery(GET_JOBS, { variables: { params } })
  const jobStats = useQuery(GET_JOB_STATS)
  
  const [upsertJobMutation] = useMutation(UPSERT_JOB, { refetchQueries: [GET_JOBS, GET_USER] })
  const [deleteJobMutation] = useMutation(DELETE_JOB, { refetchQueries: [GET_JOBS, GET_USER] })
  
  const jobs = data?.jobs ?? []
  const jobStatsData = jobStats?.data?.job_stats ?? []
  
  const upsertJob = async (input: any) => {
    const res = await upsertJobMutation({ variables: { input } })
    return res?.data?.upsertJob ?? {}
  }
  
  const deleteJob = async (id: number) => {
    const res = await deleteJobMutation({ variables: { id } })
    return res?.data?.deleteJob ?? res
  }
  
  const importJobs = async (marinaId?: number): Promise<AxiosResponse> => {
    const formData = { marina_id: marinaId }
    return postData('import', formData)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('IMPORT JOB ERROR:', error)
    });
  };
  
  
  return {
    loading,
    jobs,
    jobStatsData,
    importJobs,
    upsertJob,
    deleteJob,
    jobsError: error,
    loadingJobs: loading
  }
}
