import { useContext } from 'react';
import {jwtDecode} from 'jwt-decode';
import { useApolloClient } from '@apollo/client';

import { Context, AuthState, DecodedToken } from './context';
import {getData, postData, patchData} from '../api';
import {Marina} from '../types';
import {AxiosResponse} from 'axios';

export interface UseAuth {
  (): {
    googleLogin: (tokenString: string) => Promise<void>;
    
    emailLogin: (email: string, password: string) => Promise<AxiosResponse>;
    
    authCodeLogin: (phone: string, email: string) => Promise<AxiosResponse>;
    
    sendAuthCode: (code: number, phone?: string, email?: string) => Promise<AxiosResponse>;
    
    getUser: () => Promise<DecodedToken>;
    
    refresh: () => Promise<void>;

    signup: (
        phone: string,
        email: string,
        firstName: string,
        lastName: string,
        password?: string,
        confirmPassword?: string,
        orgName?: string
    ) => Promise<AxiosResponse>;

    changePassword: (
      newPassword: string,
      confirmPassword: string
    ) => Promise<void>;

    forgotPassword: (email: string) => Promise<void>;

    supportEmail: (subject: string, body: string) => Promise<void>;

    changeUsername: (username: string) => Promise<void>;
    
    setActiveMarina: (marina: Marina) => Promise<void>;

    logout: () => Promise<void>;

    state: AuthState;
  };
}

export const useAuth: UseAuth = () => {
  const { state, dispatch } = useContext(Context);
  const client = useApolloClient();
  
  
  const getUser = async (): Promise<any> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });
    return getData('auth/check')
      .then(({ data }) => {
        if (data?.user) {
          dispatch({
            type: 'SET_AUTHENTICATION',
            user: data?.user,
            token: ''
          });
        }
        
        return data
      })
      .catch(({ error }) => {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        });
        return error;
      });
  };
  
  const emailLogin = async (email: string, password: string): Promise<AxiosResponse> => {
    const formData = {
      email,
      password
    }
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });
    
    return postData('auth/login', formData)
    .then((res) => {
      const tokenString = res?.data?.token
      
      if (res?.status === 200) {
        dispatch({
          type: 'SET_AUTHENTICATION',
          token: tokenString,
          user: res.data.user,
        });
        return res;
      }
      
      throw new Error(res?.response?.data?.error)
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_AUTHENTICATION',
        error,
      });
    });
  };
  
  const googleLogin = async (token: string): Promise<void> => {
    
    const formData = { token }
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });
    
    return postData('auth/google/token/verify', formData)
    .then((res) => {
      if (res?.status === 200) {
        const decodedToken = jwtDecode(res.data?.token) as DecodedToken;
        if (!decodedToken?.user_id || !res.data?.user) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: 'User not found',
          })
          return
        }
        
        dispatch({
          type: 'SET_AUTHENTICATION',
          token: res.data.token,
          user: res.data.user,
        });
      } else {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error: res?.data,
        });
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_AUTHENTICATION',
        error,
      });
    });
  };
  
  const sendAuthCode = async (code: number, phone?: string, email?: string): Promise<AxiosResponse> => {
    const formData = {
      code,
      phone,
      email
    }
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });
    
    return postData('auth/login/phone/callback', formData)
    .then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: 'SET_AUTHENTICATION',
          token: res.data.token,
          user: res.data.user,
        });
      } else {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error: res?.data?.error,
        });
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: 'ERROR_AUTHENTICATION',
        error,
      });
    });
  };
  
  const authCodeLogin = async (phone: string, email: string): Promise<AxiosResponse> => {
    const formData = {
      phone,
      email
    }

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/login/phone', formData)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        });
      });
  };

  const refresh = async (): Promise<void> => {
    dispatch({
      type: 'RESET_AUTHENTICATION',
    });
  };

  const signup = async (
    phone: string,
    email: string,
    firstName: string,
    lastName: string,
    password?: string,
    confirmPassword?: string,
    orgName?: string
  ): Promise<AxiosResponse> => {
    
    const data = {
      org_name: orgName,
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      password,
      confirm_password: confirmPassword
    }
    
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/signup', data)
      .then((res) => {
        if (res?.status === 201) {
          dispatch({
            type: 'SET_AUTHENTICATION',
            token: res.data.token,
            user: res.data.user,
          });
          return res
        }
        
        throw new Error(res?.response?.data?.error)
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        })
        return error.message
      });
  };


  const changeUsername = async (username: string): Promise<void> => {
    const formData = {
      username: username.toLowerCase()
    }

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return patchData('auth/user/', formData)
      .then((res: any) => {
        if (res?.status !== 200) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
          return res
        }
        sessionStorage.setItem('logname', username.toLowerCase());
        dispatch({
          type: 'RESET_AUTHENTICATION',
        });
        return res;
      })
      .catch((error) => {
        console.log('CHANGE USERNAME ERROR:', error);
      });
  };

  const changePassword = async (
    newPassword: string,
    confirmPassword: string
  ): Promise<void> => {
    const formData = {
      new_password1: newPassword,
      new_password2: confirmPassword,
    }

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/password/change/', formData)
      .then((res) => {
        if (res?.status !== 200) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('CHANGE PASSWORD ERROR:', error);
      });
  };

  const forgotPassword = async (email: string): Promise<void> => {
    const formData = {
      email
    }
    

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/password/reset/', formData)
      .then((res) => {
        if (res?.status !== 201) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('FORGOT PASSWORD ERROR:', error);
      });
  };


  const supportEmail = async (subject: string, body: string): Promise<void> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });
    
    
    const formData = {
      subject: subject,
      message: body
    }

    return postData('support', formData)
      .then((res) => {
        if (res?.status !== 200)
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        return res;
      })
      .catch((error) => {
        console.log('SUBMIT SUPPORT ERROR:', error);
      });
  };
  
  const setActiveMarina = async (marina: Marina): Promise<void> => {
    dispatch({
      type: 'SET_ACTIVE_MARINA',
      marina
    });
  };

  const logout = async (): Promise<void> => {

    dispatch({
      type: 'CLEAR_AUTHENTICATION',
    });
    
    await client.clearStore()
    
    sessionStorage.clear();
    
    return Promise.resolve();
  };

  return {
    getUser,
    sendAuthCode,
    setActiveMarina,
    googleLogin,
    emailLogin,
    authCodeLogin,
    refresh,
    signup,
    changeUsername,
    supportEmail,
    changePassword,
    forgotPassword,
    logout,
    state,
  };
};
